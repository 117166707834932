export const styles = `<style>
* {
  box-sizing: border-box;
}

.root {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  height: 100%;
  overflow: hidden;
}

.root {
  display: flex;
  flex-direction: column;
}

.wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  position: relative;
}

.dropzone {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder {
  width: 100%;
  max-width: 500px;
  border-radius: 0.5em;
  background: #EEE;
  padding: 2em;
  text-align: center;
}

.placeholder p {
  font-size: 1.2rem;
  color: #999;
}

.viewer {
  width: 70%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: absolute;
  top: 0;
  z-index: 0;
}

.axes {
    width: 100px;
    height: 100px;
    margin: 20px;
    padding: 0px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 10;
    pointer-events: none;
}

.gui-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.gui-wrap > .main {
  pointer-events: all;
  max-height: 100%;
  overflow: auto;
}

.dg li.gui-stats:not(.folder) {
  height: auto;
}
.dg ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  clear: both;
}
.dg.ac {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 0;
}
.dg:not(.ac) .main {
  overflow: hidden;
}
.dg.main {
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}
.dg.main.taller-than-window {
  overflow-y: auto;
}
.dg.main.taller-than-window .close-button {
  opacity: 1;
  margin-top: -1px;
  border-top: 1px solid #2c2c2c;
}
.dg.main ul.closed .close-button {
  opacity: 1 !important;
}
.dg.main:hover .close-button,
.dg.main .close-button.drag {
  opacity: 1;
}
.dg.main .close-button {
  -webkit-transition: opacity 0.1s linear;
  -o-transition: opacity 0.1s linear;
  -moz-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
  border: 0;
  line-height: 19px;
  height: 20px;
  cursor: pointer;
  text-align: center;
  background-color: #000;
}
.dg.main .close-button.close-top {
  position: relative;
}
.dg.main .close-button.close-bottom {
  position: absolute;
}
.dg.main .close-button:hover {
  background-color: #111;
}
.dg.a {
  float: right;
  margin-right: 15px;
  overflow-y: visible;
}
.dg.a.has-save > ul.close-top {
  margin-top: 0;
}
.dg.a.has-save > ul.close-bottom {
  margin-top: 27px;
}
.dg.a.has-save > ul.closed {
  margin-top: 0;
}
.dg.a .save-row {
  top: 0;
  z-index: 1002;
}
.dg.a .save-row.close-top {
  position: relative;
}
.dg.a .save-row.close-bottom {
  position: fixed;
}
.dg li {
  -webkit-transition: height 0.1s ease-out;
  -o-transition: height 0.1s ease-out;
  -moz-transition: height 0.1s ease-out;
  transition: height 0.1s ease-out;
  -webkit-transition: overflow 0.1s linear;
  -o-transition: overflow 0.1s linear;
  -moz-transition: overflow 0.1s linear;
  transition: overflow 0.1s linear;
}
.dg li:not(.folder) {
  cursor: auto;
  height: 27px;
  line-height: 27px;
  padding: 0 4px 0 5px;
}
.dg li.folder {
  padding: 0;
  border-left: 4px solid rgba(0, 0, 0, 0);
}
.dg li.title {
  cursor: pointer;
  margin-left: -4px;
}
.dg .closed li:not(.title),
.dg .closed ul li,
.dg .closed ul li > * {
  height: 0;
  overflow: hidden;
  border: 0;
}
.dg .cr {
  clear: both;
  padding-left: 3px;
  height: 27px;
  overflow: hidden;
}
.dg .property-name {
  cursor: default;
  float: left;
  clear: left;
  width: 40%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dg .c {
  float: left;
  width: 60%;
  position: relative;
}
.dg .c input[type="text"] {
  border: 0;
  margin-top: 4px;
  padding: 3px;
  width: 100%;
  float: right;
}
.dg .has-slider input[type="text"] {
  width: 30%;
  margin-left: 0;
}
.dg .slider {
  float: left;
  width: 66%;
  margin-left: -5px;
  margin-right: 0;
  height: 19px;
  margin-top: 4px;
}
.dg .slider-fg {
  height: 100%;
}
.dg .c input[type="checkbox"] {
  margin-top: 7px;
}
.dg .c select {
  margin-top: 5px;
}
.dg .cr.function,
.dg .cr.function .property-name,
.dg .cr.function *,
.dg .cr.boolean,
.dg .cr.boolean * {
  cursor: pointer;
}
.dg .cr.color {
  overflow: visible;
}
.dg .selector {
  display: none;
  position: absolute;
  margin-left: -9px;
  margin-top: 23px;
  z-index: 10;
}
.dg .c:hover .selector,
.dg .selector.drag {
  display: block;
}
.dg li.save-row {
  padding: 0;
}
.dg li.save-row .button {
  display: inline-block;
  padding: 0px 6px;
}
.dg.dialogue {
  background-color: #222;
  width: 460px;
  padding: 15px;
  font-size: 13px;
  line-height: 15px;
}
#dg-new-constructor {
  padding: 10px;
  color: #222;
  font-family: Monaco, monospace;
  font-size: 10px;
  border: 0;
  resize: none;
  box-shadow: inset 1px 1px 1px #888;
  word-wrap: break-word;
  margin: 12px 0;
  display: block;
  width: 440px;
  overflow-y: scroll;
  height: 100px;
  position: relative;
}
#dg-local-explain {
  display: none;
  font-size: 11px;
  line-height: 17px;
  border-radius: 3px;
  background-color: #333;
  padding: 8px;
  margin-top: 10px;
}
#dg-local-explain code {
  font-size: 10px;
}
#dat-gui-save-locally {
  display: none;
}
.dg {
  color: #eee;
  font: 11px "Lucida Grande", sans-serif;
  text-shadow: 0 -1px 0 #111;
}
.dg.main::-webkit-scrollbar {
  width: 5px;
  background: #1a1a1a;
}
.dg.main::-webkit-scrollbar-corner {
  height: 0;
  display: none;
}
.dg.main::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #676767;
}
.dg li:not(.folder) {
  background: #1a1a1a;
  border-bottom: 1px solid #2c2c2c;
}
.dg li.save-row {
  line-height: 25px;
  background: #dad5cb;
  border: 0;
}
.dg li.save-row select {
  margin-left: 5px;
  width: 108px;
}
.dg li.save-row .button {
  margin-left: 5px;
  margin-top: 1px;
  border-radius: 2px;
  font-size: 9px;
  line-height: 7px;
  padding: 4px 4px 5px 4px;
  background: #c5bdad;
  color: #fff;
  text-shadow: 0 1px 0 #b0a58f;
  box-shadow: 0 -1px 0 #b0a58f;
  cursor: pointer;
}
.dg li.save-row .button.gears {
  background: #c5bdad
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAANCAYAAAB/9ZQ7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAQJJREFUeNpiYKAU/P//PwGIC/ApCABiBSAW+I8AClAcgKxQ4T9hoMAEUrxx2QSGN6+egDX+/vWT4e7N82AMYoPAx/evwWoYoSYbACX2s7KxCxzcsezDh3evFoDEBYTEEqycggWAzA9AuUSQQgeYPa9fPv6/YWm/Acx5IPb7ty/fw+QZblw67vDs8R0YHyQhgObx+yAJkBqmG5dPPDh1aPOGR/eugW0G4vlIoTIfyFcA+QekhhHJhPdQxbiAIguMBTQZrPD7108M6roWYDFQiIAAv6Aow/1bFwXgis+f2LUAynwoIaNcz8XNx3Dl7MEJUDGQpx9gtQ8YCueB+D26OECAAQDadt7e46D42QAAAABJRU5ErkJggg==)
    2px 1px no-repeat;
  height: 7px;
  width: 8px;
}
.dg li.save-row .button:hover {
  background-color: #bab19e;
  box-shadow: 0 -1px 0 #b0a58f;
}
.dg li.folder {
  border-bottom: 0;
}
.dg li.title {
  padding-left: 16px;
  background: #000
    url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlI+hKgFxoCgAOw==)
    6px 10px no-repeat;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.dg .closed li.title {
  background-image: url(data:image/gif;base64,R0lGODlhBQAFAJEAAP////Pz8////////yH5BAEAAAIALAAAAAAFAAUAAAIIlGIWqMCbWAEAOw==);
}
.dg .cr.boolean {
  border-left: 3px solid #806787;
}
.dg .cr.color {
  border-left: 3px solid;
}
.dg .cr.function {
  border-left: 3px solid #e61d5f;
}
.dg .cr.number {
  border-left: 3px solid #2fa1d6;
}
.dg .cr.number input[type="text"] {
  color: #2fa1d6;
}
.dg .cr.string {
  border-left: 3px solid #1ed36f;
}
.dg .cr.string input[type="text"] {
  color: #1ed36f;
}
.dg .cr.function:hover,
.dg .cr.boolean:hover {
  background: #111;
}
.dg .c input[type="text"] {
  background: #303030;
  outline: none;
}
.dg .c input[type="text"]:hover {
  background: #3c3c3c;
}
.dg .c input[type="text"]:focus {
  background: #494949;
  color: #fff;
}
.dg .c .slider {
  background: #303030;
  cursor: ew-resize;
}
.dg .c .slider-fg {
  background: #2fa1d6;
  max-width: 100%;
}
.dg .c .slider:hover {
  background: #3c3c3c;
}
.dg .c .slider:hover .slider-fg {
  background: #44abda;
}


/******************************************************************************
 * Upload Button
 *
 * https://tympanus.net/Tutorials/CustomFileInputs/
 */

.upload-btn {
  margin-top: 2em;
}

.upload-btn input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.upload-btn label {
  color: #353535;
  border: 0;
  border-radius: 3px;
  transition:background ease 0.2s;
  font-size: 1rem;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  padding: 0.625rem 1.25rem;
}

.upload-btn label:hover {
  background: #DDD;
}

.upload-btn svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}
 /* CSS Spinner
 *
 * http://tobiasahlin.com/spinkit/
 */

.spinner {
  display: block;
  position: absolute;
  left: 36%;
  top: 50%;
  margin: -20px;
  width: 80px;
  height: 80px;
  z-index: 1;
}
.spinner div {
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #333;
  margin: -4px 0 0 -4px;
}
.spinner div:nth-child(1) {
  animation-delay: -0.036s;
}
.spinner div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.spinner div:nth-child(2) {
  animation-delay: -0.072s;
}
.spinner div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.spinner div:nth-child(3) {
  animation-delay: -0.108s;
}
.spinner div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.spinner div:nth-child(4) {
  animation-delay: -0.144s;
}
.spinner div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.spinner div:nth-child(5) {
  animation-delay: -0.18s;
}
.spinner div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.spinner div:nth-child(6) {
  animation-delay: -0.216s;
}
.spinner div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.spinner div:nth-child(7) {
  animation-delay: -0.252s;
}
.spinner div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.spinner div:nth-child(8) {
  animation-delay: -0.288s;
}
.spinner div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.color-switch {
  padding-left: 10px;
}

</style>`;
