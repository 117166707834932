import {
  AmbientLight,
  AnimationMixer,
  AxesHelper,
  Box3,
  Cache,
  CubeTextureLoader,
  DirectionalLight,
  GridHelper,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  RGBFormat,
  Scene,
  SkeletonHelper,
  UnsignedByteType,
  Vector3,
  Vector2,
  WebGLRenderer,
  sRGBEncoding,
  TextureLoader,
  RepeatWrapping,
  MeshStandardMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  CanvasTexture,
  MeshNormalMaterial,
  MeshBasicMaterial,
  MeshDepthMaterial,
  MeshPhysicalMaterial,
} from "three";
import Stats from "three/examples/jsm/libs/stats.module.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
// import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';

import { GUI } from "dat.gui";

import './app';

import { createBackground } from "../lib/three-vignette.js";

// TODO

// Attributes
// *** Color changing
// *** List of materials
// Toggle dirty map


const DEFAULT_CAMERA = "[default]";

const IS_IOS = isIOS();

// glTF texture types. `envMap` is deliberately omitted, as it's used internally
// by the loader but not part of the glTF format.
const MAP_NAMES = [
  "map",
  "aoMap",
  "emissiveMap",
  "glossinessMap",
  "metalnessMap",
  "normalMap",
  "roughnessMap",
  "specularMap",
];

const environments = [
  {
    id: "",
    name: "None",
    path: null,
    format: ".hdr",
  },
  {
    id: "photo_box",
    name: "Photo Box",
    path: "https://gudhub.com/userdata/17763/730981.hdr",
    format: ".hdr",
  },
  {
    id: "venice-sunset",
    name: "Venice Sunset",
    path: "https://gudhub.com/userdata/17763/730980.hdr",
    format: ".hdr",
  },
  {
    id: "studio",
    name: "Studio",
    path: "https://gudhub.com/userdata/17763/730979.hdr",
    format: ".hdr",
  },
  {
    id: "office",
    name: "Office",
    path: "https://gudhub.com/userdata/17763/732593.hdr",
    format: ".hdr",
  },
  {
    id: "bridge",
    name: "Bridge",
    path: "https://gudhub.com/userdata/17763/732594.hdr",
    format: ".hdr",
  },
  {
    id: "street",
    name: "Street",
    path: "https://gudhub.com/userdata/17763/732595.hdr",
    format: ".hdr",
  },
];

const Preset = { ASSET_GENERATOR: "assetgenerator" };

Cache.enabled = true;

export class Viewer {
  constructor(el, options) {
    this.el = el;
    this.options = options;

    this.lights = [];
    this.content = null;
    this.mixer = null;
    this.clips = [];
    this.gui = null;
    this.activeTexture = null;

    this.state = {
      environment:
        options.preset === Preset.ASSET_GENERATOR
          ? environments.find((e) => e.id === "studio").name
          : environments[3].name,
      background: false,
      background2: 1,
      playbackSpeed: 1.0,
      actionStates: {},
      camera: DEFAULT_CAMERA,
      wireframe: false,
      skeleton: false,
      grid: false,

      // Lights
      Light: false,
      exposure: 1,
      textureEncoding: "Linear",
      ambientIntensity: 1.8,
      ambientColor: "#ffffff",
      directIntensity: 0, //0.8 * Math.PI, // TODO(#116)
      directColor: 0x000000,
      bgColor1: "#ffffff",
      bgColor2: "#ffffff",

      //Reflections
      roughness: 0.3,
      metalness: 0,

      // Scene rotatino

      EnviromentRotation: 3,

      // Material
      material: 'standard',
      dirtyColor: '#ff0000',
      dirtyMapIntensity: 0.3,

      lightMapUrl: 'https://gudhub.com/userdata/24143/812439.jpg',
      dirtyMapUrl: 'https://gudhub.com/userdata/24143/934538.png',

      test: 'sadassadas',


    };

    this.prevTime = 0;

    this.stats = new Stats();
    this.stats.dom.height = "48px";
    [].forEach.call(
      this.stats.dom.children,
      (child) => (child.style.display = "")
    );

    this.scene = new Scene();

    const fov =
      options.preset === Preset.ASSET_GENERATOR ? (0.8 * 180) / Math.PI : 60;
    this.defaultCamera = new PerspectiveCamera(
      fov,
      el.clientWidth / el.clientHeight,
      0.01,
      1000
    );
    this.activeCamera = this.defaultCamera;
    this.scene.add(this.defaultCamera);

    this.renderer = window.renderer = new WebGLRenderer({
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = LinearEncoding;
    this.renderer.setClearColor(0xcccccc);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(el.clientWidth, el.clientHeight);

    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.controls = new OrbitControls(
      this.defaultCamera,
      this.renderer.domElement
    );
    this.controls.autoRotate = false;
    this.controls.autoRotateSpeed = -10;
    this.controls.screenSpacePanning = true;

    this.vignette = createBackground({
      aspect: this.defaultCamera.aspect,
      grainScale: IS_IOS ? 0 : 0.001, // mattdesl/three-vignette-background#1
      colors: [this.state.bgColor1, this.state.bgColor2],
    });
    this.vignette.name = "Vignette";
    this.vignette.renderOrder = -1;

    this.el.appendChild(this.renderer.domElement);

    this.cameraCtrl = null;
    this.cameraFolder = null;
    this.animFolder = null;
    this.animCtrls = [];
    this.morphFolder = null;
    this.morphCtrls = [];
    this.skeletonHelpers = [];
    this.gridHelper = null;
    this.axesHelper = null;

    this.texture = null;

    this.addAxesHelper();
    if (this.options.showControlPanel) {
      this.addGUI();
    }

    this.addResetButton();
    this.addTakeSnapshotButton();

    if (options.kiosk) this.gui.close();

    this.animate = this.animate.bind(this);
    requestAnimationFrame(this.animate);
    window.addEventListener("resize", this.resize.bind(this), false);
  }

  animate(time) {
    requestAnimationFrame(this.animate);

    const dt = (time - this.prevTime) / 1000;

    this.controls.update();
    this.stats.update();
    this.mixer && this.mixer.update(dt);
    this.render();

    this.prevTime = time;
  }

  render() {
    this.renderer.render(this.scene, this.activeCamera);
    if (this.state.grid) {
      this.axesCamera.position.copy(this.defaultCamera.position);
      this.axesCamera.lookAt(this.axesScene.position);
      this.axesRenderer.render(this.axesScene, this.axesCamera);
    }
  }

  resize() {
    const { clientHeight, clientWidth } = this.el;

    this.defaultCamera.aspect = clientWidth / clientHeight;
    this.defaultCamera.updateProjectionMatrix();
    this.vignette.style({ aspect: this.defaultCamera.aspect });
    this.renderer.setSize(clientWidth, clientHeight);

    this.axesCamera.aspect =
      this.axesDiv.clientWidth / this.axesDiv.clientHeight;
    this.axesCamera.updateProjectionMatrix();
    this.axesRenderer.setSize(
      this.axesDiv.clientWidth,
      this.axesDiv.clientHeight
    );
  }

  load(url, rootPath, assetMap) {
    this.controls.reset();
    const baseURL = LoaderUtils.extractUrlBase(url);
    // Load.
    return new Promise((resolve, reject) => {
      const manager = new LoadingManager();

      // Intercept and override relative URLs.
      manager.setURLModifier((url, path) => {
        // URIs in a glTF file may be escaped, or not. Assume that assetMap is
        // from an un-escaped source, and decode all URIs before lookups.
        // See: https://github.com/donmccurdy/three-gltf-viewer/issues/146
        const normalizedURL =
          rootPath +
          decodeURI(url)
            .replace(baseURL, "")
            .replace(/^(\.?\/)/, "");
        if (assetMap instanceof Map && assetMap.has(normalizedURL)) {
          const blob = assetMap.get(normalizedURL);
          const blobURL = URL.createObjectURL(blob);
          blobURLs.push(blobURL);
          return blobURL;
        }

        return (path || "") + url;
      });

      const loader = new GLTFLoader(manager)
        .setCrossOrigin("anonymous")
        .setDRACOLoader(new DRACOLoader(manager).setDecoderPath("assets/wasm/"))
        .setKTX2Loader(
          new KTX2Loader(manager)
            .setTranscoderPath("assets/wasm/")
            .detectSupport(this.renderer)
        )
        .setMeshoptDecoder(MeshoptDecoder);

      const blobURLs = [];

      loader.load(
        url,
        (gltf) => {
          const scene = gltf.scene || gltf.scenes[0];
          const clips = gltf.animations || [];

          if (!scene) {
            // Valid, but not supported by this viewer.
            throw new Error(
              `This model contains no scene, and cannot be viewed here. However, it may contain individual 3D resources.`
            );
          }

          this.setContent(scene, clips);

          blobURLs.forEach(URL.revokeObjectURL);

          // See: https://github.com/google/draco/issues/349
          // DRACOLoader.releaseDecoderModule();

          if (this.content) {

            this.content.traverse(o => {

              if (o.isMesh) {

                o.visible = false;

              }

            });

          }

          resolve(gltf);
        },
        undefined,
        reject
      );
    });

  }

  loadTexture(url) {
    if (url == null) {
      url = this.activeTexture;
    }
    this.activeTexture = url;
    console.log(this.state.lightMapUrl);
    console.log(this.state.dirtyMapUrl);
    return new Promise((resolve) => {
      const loader = new TextureLoader();
      loader.load(
        url,
        (texture) => {
          texture.flipY = false;
          texture.wrapS = RepeatWrapping;
          texture.wrapT = RepeatWrapping;

          const textureCanvas = document.createElement('canvas');
          const textureCtx = textureCanvas.getContext('2d');

          let textureImage = new Image();

          let textureWidth = 100;
          let textureHeight = 100;

          textureImage.src = this.state.dirtyMapUrl;
          textureImage.crossOrigin = 'anonymus';

          textureImage.onload = () => {
            textureWidth = textureImage.naturalWidth;
            textureHeight = textureImage.naturalHeight;

            textureCanvas.width = textureWidth;
            textureCanvas.height = textureHeight;
            
            textureCtx.drawImage(textureImage, 0, 0);
            textureCtx.globalCompositeOperation = 'screen';
            textureCtx.fillStyle = this.state.dirtyColor.replace('0x', '#');
            textureCtx.fillRect(0, 0, textureWidth, textureHeight);
            textureCtx.globalCompositeOperation = 'multiply';
            textureCtx.drawImage(texture.image, 0, 0);

            let textureDataUrl = textureCanvas.toDataURL();
            let textureImageElement = document.createElement('img');
            textureImageElement.src = textureDataUrl;

            texture.image = textureImageElement;
          }

          if (this.content) {
            this.content.traverse((o) => {
              if (o.isMesh) {

                o.visible = false;

                let lightMapLoader = new TextureLoader();

                /*let light = lightMapLoader.load('https://gudhub.com/userdata/24143/804851.png');

                light.flipY = false;*/

                this.makeCanvasTexture().then((canvasTexture) => {

                  lightMapLoader.load(
                    this.state.lightMapUrl,

                    (light) => {

                      light.flipY = false;

                      let materialSettings = {
                        map: texture,
                        metalness: this.state.metalness,
                        roughness: this.state.roughness,
                        lightMap: canvasTexture,
                        lightMapIntensity: 1,
                      }

                      let materials = {
                        standard: new MeshStandardMaterial(materialSettings),
                        phong: new MeshPhongMaterial(materialSettings),
                        lambert: new MeshLambertMaterial(materialSettings),
                        normal: new MeshNormalMaterial(materialSettings),
                        depth: new MeshDepthMaterial(materialSettings),
                        basic: new MeshBasicMaterial(materialSettings),
                        physical: new MeshPhysicalMaterial(materialSettings),
                      }

                      console.log(materials);

                      console.log(materials[this.state.material]);

                      //this.material = DRAWER_MATERIAL;

                      o.material = materials[this.state.material];

                      console.log(this.state.dirtyMapUrl, this.state.lightMapUrl);

                      console.log(o);

                      o.visible = true;

                      resolve();

                    }
                  );

                });

              }
            });
          } else {
            resolve();
          }
        },
        null,
        (error) => {
          console.log(error);
          this.texture = null;
          resolve();
        }
      );
    });
  }

  changeDirtyMapColor(color) {
    this.state.dirtyColor = color;
    this.loadTexture(this.activeTexture);
  }

  changeMaterial(materialName) {
    this.state.material = materialName;
    this.loadTexture(this.activeTexture);
  }

  changeLightmap(lightMapUrl) {
    return new Promise(resolve => {
    this.state.lightMapUrl = lightMapUrl;
    this.loadTexture(this.activeTexture).then(() => {
      resolve();
    })

  });
  }

  changeDirtymap(dirtyMapUrl) {
    this.state.dirtyMapUrl = dirtyMapUrl;
    this.loadTexture(this.activeTexture);
  }

  changeDirtyMapIntensity(intensity) {
    this.state.dirtyMapIntensity = intensity;
    this.loadTexture(this.activeTexture);
  }

  makeRgbColor = (color) => {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  makeCanvasTexture() {

    let rgbColor = this.makeRgbColor(this.state.dirtyColor);

    return new Promise((resolve) => {

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const patinaCanvas = document.createElement('canvas');
      const patinaCtx = patinaCanvas.getContext('2d');
      let width = 100;
      let height = 100;

      let patina = new Image();

      patina.src = this.state.dirtyMapUrl;
      patina.crossOrigin = 'anonymus';

      patina.onload = () => {

        width = patina.naturalWidth;
        height = patina.naturalHeight;

        patinaCanvas.width = width;
        patinaCanvas.height = height;

        patinaCtx.drawImage(patina, 0, 0);

        let pixelsData = patinaCtx.getImageData(0, 0, width, height).data;

        let alphaData = [];

        let i = 3;

        console.time('test');

        for (i = 3; i < pixelsData.length; i += 4) {
          alphaData.push(rgbColor.r, rgbColor.g, rgbColor.b, (255 * this.state.dirtyMapIntensity) - ((pixelsData[i - 3] + pixelsData[i - 2] + pixelsData[i - 1]) / 3));
        }

        console.timeEnd('test');

        let newImageData = patinaCtx.getImageData(0, 0, width, height);
        newImageData.data.set(alphaData);
        console.log(newImageData)

        patinaCtx.putImageData(newImageData, 0, 0);
        patinaCtx.globalAlpha = 1;

        let lightmap = new Image();

        lightmap.src = this.state.lightMapUrl;
        lightmap.crossOrigin = 'anonymus';


        lightmap.onload = () => {

          width = lightmap.naturalWidth;
          height = lightmap.naturalHeight;

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(lightmap, 0, 0);
          //ctx.globalCompositeOperation = 'multiply';
          ctx.drawImage(patinaCanvas, 0, 0);

          let canvasTexture = new CanvasTexture(canvas);

          canvasTexture.flipY = false;

          console.log(canvasTexture);

          resolve(canvasTexture);

        }

      }
    })

  }

  /**
   * @param {THREE.Object3D} object
   * @param {Array<THREE.AnimationClip} clips
   */

  resetCamera() {
    this.controls.reset();
  }

  setContent(object, clips) {
    this.clear();
    this.box = new Box3().setFromObject(object);
    const size = this.box.getSize(new Vector3()).length();
    const center = this.box.getCenter(new Vector3());
    if (this.cameraSetted) {
      this.cameraOptions = { ...this.defaultCamera.position };
    }

    object.position.x += object.position.x - center.x;
    object.position.y += object.position.y - center.y;
    object.position.z += object.position.z - center.z;
    this.controls.minDistance = 0.1;
    this.controls.maxDistance = size;

    this.controls.maxAzimuthAngle = Math.PI / 2;
    this.controls.minAzimuthAngle = -Math.PI / 2;

    this.defaultCamera.near = size / 100;
    this.defaultCamera.far = size * 100;

    window.defaultCamera = this.defaultCamera;
    window.size = size;

    if (this.options.cameraPosition) {
      this.defaultCamera.position.fromArray(this.options.cameraPosition);
      this.defaultCamera.lookAt(new Vector3());
    } else if (!this.cameraSetted) {
      this.defaultCamera.position.copy(center);
      this.defaultCamera.position.x = 0;
      this.defaultCamera.position.y = 0;
      this.defaultCamera.position.z += size;
      this.defaultCamera.lookAt(center);
      this.cameraSetted = true;
    }
    this.setCamera(DEFAULT_CAMERA);

    this.axesCamera.position.copy(this.defaultCamera.position);
    this.axesCamera.lookAt(this.axesScene.position);
    this.axesCamera.near = size / 100;
    this.axesCamera.far = size * 100;
    this.axesCamera.updateProjectionMatrix();
    this.axesCorner.scale.set(size, size, size);

    this.controls.saveState();

    this.scene.add(object);
    this.content = object;

    this.state.Light = true;

    this.content.traverse((node) => {
      if (node.isLight) {
        this.state.Light = true;
      } else if (node.isMesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
        node.material.depthWrite = !node.material.transparent;
        if (this.texture) {
          node.material.map = this.texture;
        }
      }
    });

    this.setClips(clips);

    this.updateLights();
    if (this.options.showControlPanel) {
      this.updateGUI();
    }
    //this.updateEnvironment();
    this.updateTextureEncoding();
    this.updateDisplay();

    window.content = this.content;
    console.info("[glTF Viewer] THREE.Scene exported as `window.content`.");
    this.printGraph(this.content);
  }

  printGraph(node) {
    console.group(" <" + node.type + "> " + node.name);
    node.children.forEach((child) => this.printGraph(child));
    console.groupEnd();
  }

  /**
   * @param {Array<THREE.AnimationClip} clips
   */
  setClips(clips) {
    if (this.mixer) {
      this.mixer.stopAllAction();
      this.mixer.uncacheRoot(this.mixer.getRoot());
      this.mixer = null;
    }

    this.clips = clips;
    if (!clips.length) return;

    this.mixer = new AnimationMixer(this.content);
  }

  playAllClips() {
    this.clips.forEach((clip) => {
      this.mixer.clipAction(clip).reset().play();
      this.state.actionStates[clip.name] = true;
    });
  }

  /**
   * @param {string} name
   */
  setCamera(name) {
    if (name === DEFAULT_CAMERA) {
      this.controls.enabled = true;
      this.activeCamera = this.defaultCamera;
    } else {
      this.controls.enabled = false;
      this.content.traverse((node) => {
        if (node.isCamera && node.name === name) {
          this.activeCamera = node;
        }
      });
    }
  }

  updateTextureEncoding() {
    const encoding =
      this.state.textureEncoding === "sRGB" ? sRGBEncoding : LinearEncoding;
    traverseMaterials(this.content, (material) => {
      if (material.map) material.map.encoding = encoding;
      if (material.emissiveMap) material.emissiveMap.encoding = encoding;
      if (material.map || material.emissiveMap) material.needsUpdate = true;
    });
  }

  addResetButton() {
    const btn = document.createElement("button");

    btn.innerText = "Reset camera";
    btn.style.position = "absolute";
    btn.style.top = "20px";
    btn.style.left = "50%";
    btn.style.cursor = "pointer";
    btn.style.padding = "5px 10px";
    btn.style.color = "#fff";
    btn.style.backgroundColor = "#5a6268";
    btn.style.border = "1px solid #545b62";
    btn.style.borderRadius = "5px";
    btn.style.transform = "translateX(-50%)";

    btn.addEventListener("click", () => {
      this.resetCamera();
    });

    this.el.appendChild(btn);
  }

  addTakeSnapshotButton() {
    const btn = document.createElement("button");

    btn.innerText = "Snapshot";
    btn.style.position = "absolute";
    btn.style.top = "20px";
    btn.style.left = "20px";
    btn.style.cursor = "pointer";
    btn.style.padding = "5px 10px";
    btn.style.color = "#fff";
    btn.style.backgroundColor = "#5a6268";
    btn.style.border = "1px solid #545b62";
    btn.style.borderRadius = "5px";

    btn.addEventListener("click", () => {
      this.resetCamera();
      const width = this.options.outputImageSize.width || 2000;
      const height = this.options.outputImageSize.height || 2000;
      this.defaultCamera.aspect = width / height;
      this.defaultCamera.updateProjectionMatrix();
      this.renderer.setSize(width, height);

      this.renderer.render(this.scene, this.activeCamera);

      const a = document.createElement("a");
      a.href = this.renderer.domElement.toDataURL("image/png");
      a.download = "file.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.resize();
    });

    this.el.appendChild(btn);
  }

  updateLights() {
    const state = this.state;
    const lights = this.lights;

    if(state.ambientColor.indexOf('0x') < 0) {
      let hexStateColor = state.ambientColor.substring(1);
      hexStateColor = '0x' + hexStateColor;
      this.state.ambientColor = hexStateColor;
    }

    if (state.Light && !lights.length) {
      this.addLights();
    } else if (!state.Light && lights.length) {
      this.removeLights();
    }

    this.renderer.toneMappingExposure = state.exposure;

    if (lights.length === 2) {
      lights[0].intensity = state.ambientIntensity;
      lights[0].color.setHex(state.ambientColor);
      lights[1].intensity = state.directIntensity;
      lights[1].color.setHex(state.directColor);
    }
  }

  addLights() {
    const state = this.state;

    if (this.options.preset === Preset.ASSET_GENERATOR) {
      const hemiLight = new HemisphereLight();
      hemiLight.name = "hemi_light";
      this.scene.add(hemiLight);
      this.lights.push(hemiLight);
      return;
    }

    const light1 = new AmbientLight(state.ambientColor, state.ambientIntensity);
    light1.name = "ambient_light";
    this.defaultCamera.add(light1);

    const light2 = new DirectionalLight(
      state.directColor,
      state.directIntensity
    );
    light2.position.set(0.5, 0, 0.866); // ~60º
    light2.name = "main_light";
    this.defaultCamera.add(light2);

    this.lights.push(light1, light2);
  }

  removeLights() {
    this.lights.forEach((light) => light.parent.remove(light));
    this.lights.length = 0;
  }

  updateEnvironment() {
    const environment = environments.filter(
      (entry) => entry.name === this.state.environment
    )[0];

    this.getCubeMapTexture(environment).then(({ envMap }) => {
      if (
        (!envMap || !this.state.background) &&
        this.activeCamera === this.defaultCamera
      ) {
        this.scene.add(this.vignette);
      } else {
        this.scene.remove(this.vignette);
      }

      this.scene.environment = envMap;
      this.scene.background = this.state.background ? envMap : null;

      this.scene.rotation.y = this.state.EnviromentRotation;

    });
  }

  getCubeMapTexture(environment) {
    const { path } = environment;

    // no envmap
    if (!path) return Promise.resolve({ envMap: null });

    return new Promise((resolve, reject) => {
      new RGBELoader().setDataType(UnsignedByteType).load(
        path,
        (texture) => {
          const envMap =
            this.pmremGenerator.fromEquirectangular(texture).texture;
          this.pmremGenerator.dispose();

          resolve({ envMap });
        },
        undefined,
        reject
      );
    });
  }

  updateDisplay() {
    if (this.skeletonHelpers.length) {
      this.skeletonHelpers.forEach((helper) => this.scene.remove(helper));
    }

    traverseMaterials(this.content, (material) => {
      material.wireframe = this.state.wireframe;
    });

    this.content.traverse((node) => {
      if (node.isMesh && node.skeleton && this.state.skeleton) {
        const helper = new SkeletonHelper(node.skeleton.bones[0].parent);
        helper.material.linewidth = 3;
        this.scene.add(helper);
        this.skeletonHelpers.push(helper);
      }
    });

    if (this.state.grid !== Boolean(this.gridHelper)) {
      if (this.state.grid) {
        this.gridHelper = new GridHelper();
        this.axesHelper = new AxesHelper();
        this.axesHelper.renderOrder = 999;
        this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
        this.scene.add(this.gridHelper);
        this.scene.add(this.axesHelper);
      } else {
        this.scene.remove(this.gridHelper);
        this.scene.remove(this.axesHelper);
        this.gridHelper = null;
        this.axesHelper = null;
        this.axesRenderer.clear();
      }
    }
  }

  updateBackground() {
    this.vignette.style({ colors: [this.state.bgColor1, this.state.bgColor2] });
  }

  /**
   * Adds AxesHelper.
   *
   * See: https://stackoverflow.com/q/16226693/1314762
   */
  addAxesHelper() {
    this.axesDiv = document.createElement("div");
    this.el.appendChild(this.axesDiv);
    this.axesDiv.classList.add("axes");

    const { clientWidth, clientHeight } = this.axesDiv;

    this.axesScene = new Scene();
    this.axesCamera = new PerspectiveCamera(
      50,
      clientWidth / clientHeight,
      0.1,
      10
    );
    this.axesScene.add(this.axesCamera);

    this.axesRenderer = new WebGLRenderer({ alpha: true });
    this.axesRenderer.setPixelRatio(window.devicePixelRatio);
    this.axesRenderer.setSize(
      this.axesDiv.clientWidth,
      this.axesDiv.clientHeight
    );

    this.axesCamera.up = this.defaultCamera.up;

    this.axesCorner = new AxesHelper(5);
    this.axesScene.add(this.axesCorner);
    this.axesDiv.appendChild(this.axesRenderer.domElement);
  }

  addGUI() {
    const gui = (this.gui = new GUI({
      autoPlace: false,
      width: 260,
      hideable: true,
    }));

    // Display controls.
    const dispFolder = gui.addFolder("Display");
    const envBackgroundCtrl = dispFolder.add(this.state, "background");
    envBackgroundCtrl.onChange(() => this.updateEnvironment());
    const wireframeCtrl = dispFolder.add(this.state, "wireframe");
    wireframeCtrl.onChange(() => this.updateDisplay());
    /* const skeletonCtrl = dispFolder.add(this.state, "skeleton");
    skeletonCtrl.onChange(() => this.updateDisplay());
    const gridCtrl = dispFolder.add(this.state, "grid");
    gridCtrl.onChange(() => this.updateDisplay());
    dispFolder.add(this.controls, "autoRotate");
    dispFolder.add(this.controls, "screenSpacePanning"); */
    const bgColor1Ctrl = dispFolder.addColor(this.state, "bgColor1");
    const bgColor2Ctrl = dispFolder.addColor(this.state, "bgColor2");
    bgColor1Ctrl.onChange(() => this.updateBackground());
    bgColor2Ctrl.onChange(() => this.updateBackground());

    // Reflections Controls

    const reflectionFolder = gui.addFolder('Enviroment & Reflection');
    let roughnessCtrl = reflectionFolder.add(this.state, 'roughness', 0, 1);
    let metalnessCtrl = reflectionFolder.add(this.state, 'metalness', 0, 1);
    let sceneRotationY = reflectionFolder.add(this.state, 'EnviromentRotation', 0, 6.28);
    const envMapCtrl = reflectionFolder.add(
      this.state,
      "environment",
      environments.map((env) => env.name)
    );

    roughnessCtrl.onChange(() => {
      this.scene.traverse((o) => {
        if (o.isMesh) {

          o.material.roughness = this.state.roughness;

        }
      });
    });

    metalnessCtrl.onChange(() => {
      this.scene.traverse((o) => {
        if (o.isMesh) {

          o.material.metalness = this.state.metalness;

        }
      });
    });

    sceneRotationY.onChange(() => {
      this.updateEnvironment();
    });

    envMapCtrl.onChange(() => this.updateEnvironment());

    // Lighting controls.
    const lightFolder = gui.addFolder("Lighting");
    /* const encodingCtrl = lightFolder.add(this.state, "textureEncoding", [
      "sRGB",
      "Linear",
    ]);
    encodingCtrl.onChange(() => this.updateTextureEncoding()); */
    /* lightFolder.add(this.renderer, "outputEncoding", {
        sRGB: sRGBEncoding,
        Linear: LinearEncoding,
      })
      .onChange(() => {
        this.renderer.outputEncoding = Number(this.renderer.outputEncoding);
        traverseMaterials(this.content, (material) => {
          material.needsUpdate = true;
        });
      }); */
    [
      lightFolder.add(this.state, "Light").listen(),
      //lightFolder.add(this.state, "exposure", 0, 2),
      lightFolder.add(this.state, "ambientIntensity", 0, 2),
      lightFolder.addColor(this.state, "ambientColor"),
      //lightFolder.add(this.state, "directIntensity", 0, 4), // TODO(#116)
      //lightFolder.addColor(this.state, "directColor"),
    ].forEach((ctrl) => ctrl.onChange(() => this.updateLights()));

    // Animation controls.
    this.animFolder = gui.addFolder("Animation");
    this.animFolder.domElement.style.display = "none";
    const playbackSpeedCtrl = this.animFolder.add(
      this.state,
      "playbackSpeed",
      0,
      1
    );
    playbackSpeedCtrl.onChange((speed) => {
      if (this.mixer) this.mixer.timeScale = speed;
    });
    this.animFolder.add({ playAll: () => this.playAllClips() }, "playAll");

    // Morph target controls.
    this.morphFolder = gui.addFolder("Morph Targets");
    this.morphFolder.domElement.style.display = "none";

    // Camera controls.
    this.cameraFolder = gui.addFolder("Cameras");
    this.cameraFolder.domElement.style.display = "none";

    // Stats.
    const perfFolder = gui.addFolder("Performance");
    const perfLi = document.createElement("li");
    this.stats.dom.style.position = "static";
    perfLi.appendChild(this.stats.dom);
    perfLi.classList.add("gui-stats");
    perfFolder.__ul.appendChild(perfLi);

    const guiWrap = document.createElement("div");
    this.el.appendChild(guiWrap);
    guiWrap.classList.add("gui-wrap");
    guiWrap.appendChild(gui.domElement);
    gui.open();
  }

  updateGUI() {
    this.cameraFolder.domElement.style.display = "none";

    this.morphCtrls.forEach((ctrl) => ctrl.remove());
    this.morphCtrls.length = 0;
    this.morphFolder.domElement.style.display = "none";

    this.animCtrls.forEach((ctrl) => ctrl.remove());
    this.animCtrls.length = 0;
    this.animFolder.domElement.style.display = "none";

    const cameraNames = [];
    const morphMeshes = [];
    this.content.traverse((node) => {
      if (node.isMesh && node.morphTargetInfluences) {
        morphMeshes.push(node);
      }
      if (node.isCamera) {
        node.name = node.name || `VIEWER__camera_${cameraNames.length + 1}`;
        cameraNames.push(node.name);
      }
    });

    if (cameraNames.length) {
      this.cameraFolder.domElement.style.display = "";
      if (this.cameraCtrl) this.cameraCtrl.remove();
      const cameraOptions = [DEFAULT_CAMERA].concat(cameraNames);
      this.cameraCtrl = this.cameraFolder.add(
        this.state,
        "camera",
        cameraOptions
      );
      this.cameraCtrl.onChange((name) => this.setCamera(name));
    }

    if (morphMeshes.length) {
      this.morphFolder.domElement.style.display = "";
      morphMeshes.forEach((mesh) => {
        if (mesh.morphTargetInfluences.length) {
          const nameCtrl = this.morphFolder.add(
            { name: mesh.name || "Untitled" },
            "name"
          );
          this.morphCtrls.push(nameCtrl);
        }
        for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
          const ctrl = this.morphFolder
            .add(mesh.morphTargetInfluences, i, 0, 1, 0.01)
            .listen();
          Object.keys(mesh.morphTargetDictionary).forEach((key) => {
            if (key && mesh.morphTargetDictionary[key] === i) ctrl.name(key);
          });
          this.morphCtrls.push(ctrl);
        }
      });
    }

    if (this.clips.length) {
      this.animFolder.domElement.style.display = "";
      const actionStates = (this.state.actionStates = {});
      this.clips.forEach((clip, clipIndex) => {
        // Autoplay the first clip.
        let action;
        if (clipIndex === 0) {
          actionStates[clip.name] = true;
          action = this.mixer.clipAction(clip);
          action.play();
        } else {
          actionStates[clip.name] = false;
        }

        // Play other clips when enabled.
        const ctrl = this.animFolder.add(actionStates, clip.name).listen();
        ctrl.onChange((playAnimation) => {
          action = action || this.mixer.clipAction(clip);
          action.setEffectiveTimeScale(1);
          playAnimation ? action.play() : action.stop();
        });
        this.animCtrls.push(ctrl);
      });
    }
  }

  clear() {
    if (!this.content) return;

    this.scene.remove(this.content);

    // dispose geometry
    this.content.traverse((node) => {
      if (!node.isMesh) return;

      node.geometry.dispose();
    });

    // dispose textures
    traverseMaterials(this.content, (material) => {
      MAP_NAMES.forEach((map) => {
        if (material[map]) material[map].dispose();
      });
    });
  }
}

function traverseMaterials(object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}

// https://stackoverflow.com/a/9039885/1314762
function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
