import { App } from "./app";
import { styles } from "./styles";

class GlbViewer extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.app = null;
    this.modelUrl = this.getAttribute("model-url");
    this.textureUrl = this.getAttribute("texture-url");
    this.lightmapUrl = this.getAttribute("lightmap-url");
    this.dirtymapUrl = this.getAttribute("dirtymap-url");
    this.dritymapColor = this.getAttribute("dirtymap-color");
    this.dirtymapIntensity = this.getAttribute("dirtymap-intensity");
    this.showDropzone = this.getAttribute("dropzone");
    this.files = this.getAttribute("dropzone");
    this.showControlPanel = this.getAttribute("show-control-panel") == "true";
    this.outputImageSize = {
      width: parseInt(this.getAttribute("output-image-width")) || 2000,
      height: parseInt(this.getAttribute("output-image-height")) || 2000,
    };
    const template = document.createElement("template");

    template.innerHTML = `
      ${styles}
      <div class="root">
        <main class="wrap">
      ${
        this.showDropzone
          ? `<div class="dropzone">
            <div class="placeholder">
              <p>Drag glTF 2.0 file or folder here</p>
            </div>
            <div class="upload-btn">
              <input type="file" name="file-input[]" class="file-input" id="file-input" multiple="">
              <label for="file-input">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path></svg>
                <span>Upload</span>
              </label>
            </div>
          </div>`
          : ""
      }
           <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </main>
      </div>`;
    this.shadowRoot.appendChild(template.content.cloneNode(true));
  }

  static get observedAttributes() {
    return ["model-url", "texture-url", "lightmap-url", "dirtymap-url", "dirtymap-color", "material", "dirtymap-intensity"];
  }

  connectedCallback() {
    const root = this.shadowRoot.querySelector(".root");
    const wrapEl = this.shadowRoot.querySelector(".wrap");
    const dropEl = this.shadowRoot.querySelector(".dropzone");
    const spinnerEl = this.shadowRoot.querySelector(".spinner");
    const inputEl = this.shadowRoot.querySelector(".file-input");
    this.app = new App(
      {
        root,
        spinnerEl,
        wrapEl,
        inputs: { dropEl, inputEl, isDropzone: this.showDropzone },
        showControlPanel: this.showControlPanel,
        outputImageSize: this.outputImageSize
      },
      location
    );
    this.init();
  }
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "model-url" && this.app) {
      this.app.load(newValue);
    }
    if (name === "texture-url" && this.app) {
      this.app.loadTexture(newValue);
    }
    if(name === 'dirtymap-color' && this.app) {
      this.app.changeDirtyMapColor(newValue);
    }
    if(name === 'material' && this.app) {
      this.app.changeMaterial(newValue);
    }
    if(name === "lightmap-url" && this.app) {
      console.log(newValue)
      this.app.changeLightmap(newValue);
    }
    if(name === "dirtymap-url" && this.app) {
      this.app.changeDirtymap(newValue);
    }
    if(name === 'dirtymap-intensity' && this.app) {
      this.app.changeDirtyMapIntensity(newValue);
    }
  }

  async init() {
    if (!this.app) return null;

    if (this.textureUrl) {
      await this.app.loadTexture(this.textureUrl);
    }

    if (this.modelUrl) {
      await this.app.load(this.modelUrl);
    }
  }
}

window.customElements.define("glb-viewer", GlbViewer);

// ATRIBUTE CHANGES CALLBACK