import { WEBGL } from "three/examples/jsm/WebGL.js";
import { Viewer } from "./viewer.js";
import { SimpleDropzone } from "simple-dropzone";
import queryString from "query-string";

if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
  console.error("The File APIs are not fully supported in this browser.");
} else if (!WEBGL.isWebGLAvailable()) {
  console.error("WebGL is not supported in this browser.");
}

export class App {
  /**
   * @param  {Element} el
   * @param  {Location} location
   */
  constructor(
    { root, spinnerEl, wrapEl, inputs: { dropEl, inputEl, isDropzone }, showControlPanel, outputImageSize },
    location
  ) {
    const hash = location.hash ? queryString.parse(location.hash) : {};
    this.options = {
      model: hash.model || "",
      preset: hash.preset || "",
      showControlPanel,
      outputImageSize,
      cameraPosition: hash.cameraPosition
        ? hash.cameraPosition.split(",").map(Number)
        : null,
    };

    this.el = root;
    this.viewer = null;
    this.viewerEl = null;
    this.wrapEl = wrapEl;
    this.isDropzone = isDropzone;
    this.spinnerEl = spinnerEl;
    this.dropEl = dropEl;
    this.inputEl = inputEl;
    this.timesLoaded = 0;



    if (this.isDropzone) {
      this.createDropzone();
    }
    this.hideSpinner();

    const options = this.options;

    if (options.model) {
      this.view(options.model, "", new Map());
    }
  }

  /**
   * Sets up the drag-and-drop controller.
   */
  createDropzone() {
    const dropCtrl = new SimpleDropzone(this.dropEl, this.inputEl);
    dropCtrl.on("drop", ({ files }) => this.load(files));
    dropCtrl.on("dropstart", () => this.showSpinner());
    dropCtrl.on("droperror", () => this.hideSpinner());
  }

  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer() {
    this.viewerEl = document.createElement("div");
    this.viewerEl.classList.add("viewer");
    if (this.isDropzone) {
      this.dropEl.innerHTML = "";
      this.dropEl.appendChild(this.viewerEl);
    } else {
      this.wrapEl.appendChild(this.viewerEl);
    }

    this.viewer = new Viewer(this.viewerEl, this.options);
    return this.viewer;
  }

  /**
   * Loads a fileset provided by user action.
   * @param  {Map<string, File>} fileMap
   */
  load(fileMap) {
    let rootFile;
    let rootPath;

    if (!fileMap) {
      return this.onError("No .gltf or .glb asset found.");
    }

    if (typeof fileMap === "string") {
      return this.view(fileMap, rootPath, fileMap);
    }
    Array.from(fileMap).forEach(([path, file]) => {
      if (file.name.match(/\.(gltf|glb)$/)) {
        rootFile = file;
        rootPath = path.replace(file.name, "");
      }
    });

    if (!rootFile) {
      this.onError("No .gltf or .glb asset found.");
    }

    return this.view(rootFile, rootPath, fileMap);  }

  loadTexture(url) {
    this.showSpinner();
    const viewer = this.viewer || this.createViewer();
    viewer.updateEnvironment();
    return viewer.loadTexture(url).then(() => {
      if(this.timesLoaded == 0) {

      } /*else if (this.timesLoaded == 1) {
        setTimeout(() => {
          this.hideSpinner();
        }, 2000);
      }*/ else {
        this.hideSpinner();
      }
      this.timesLoaded++;
    });
  }

  changeDirtyMapColor(color) {
    this.viewer.changeDirtyMapColor(color);
  }

  changeMaterial(materialName) {
    this.viewer.changeMaterial(materialName);
  }

  changeLightmap(lightMapUrl) {
    this.viewer.changeLightmap(lightMapUrl).then(() => {
      this.hideSpinner();
    });
  }

  changeDirtymap(dirtyMapUrl) {
    this.viewer.changeDirtymap(dirtyMapUrl);
  }

  changeDirtyMapIntensity(intensity) {
    this.viewer.changeDirtyMapIntensity(intensity);
  }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   * @param  {Map<string, File>} fileMap
   */
  view(rootFile, rootPath, fileMap) {
    this.showSpinner();
    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer();

    const fileURL =
      typeof rootFile === "string" ? rootFile : URL.createObjectURL(rootFile);

    const cleanup = () => {
      //this.hideSpinner();
      if (typeof rootFile === "object") URL.revokeObjectURL(fileURL);
    };
    return viewer
      .load(fileURL, rootPath, fileMap)
      .catch((e) => this.onError(e))
      .then((gltf) => {
        viewer.loadTexture().then(() => {
          this.hideSpinner();
        });
        
      });
  }

  /**
   * @param  {Error} error
   */
  onError(error) {
    let message = (error || {}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message =
        "Unable to retrieve this file. Check JS console and browser network tab.";
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = "Missing texture: " + error.target.src.split("/").pop();
    }
    window.alert(message);
    console.error(error);
  }

  showSpinner() {
    this.spinnerEl.style.display = "";
  }

  hideSpinner() {
    this.spinnerEl.style.display = "none";
  }
}
